exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stuff-game-js": () => import("./../../../src/pages/stuff/game.js" /* webpackChunkName: "component---src-pages-stuff-game-js" */),
  "component---src-pages-stuff-index-js": () => import("./../../../src/pages/stuff/index.js" /* webpackChunkName: "component---src-pages-stuff-index-js" */),
  "component---src-pages-stuff-page-1-js": () => import("./../../../src/pages/stuff/page-1.js" /* webpackChunkName: "component---src-pages-stuff-page-1-js" */),
  "component---src-pages-stuff-url-transliterator-js": () => import("./../../../src/pages/stuff/url-transliterator.js" /* webpackChunkName: "component---src-pages-stuff-url-transliterator-js" */),
  "component---src-pages-tekken-index-js": () => import("./../../../src/pages/tekken/index.js" /* webpackChunkName: "component---src-pages-tekken-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-character-template-js": () => import("./../../../src/templates/CharacterTemplate.js" /* webpackChunkName: "component---src-templates-character-template-js" */)
}

